<template>
  <v-container>
    <v-row class="justify-center">
      <v-col class="d-flex flex-column align-center">
        <h1 class="text-h5 font-weight-bold mb-3">Welcome to application</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
};
</script>
